import {FormikValues} from "formik";
import axios from 'axios';

export default class DataService {

    _apiUrl = 'https://bot.sovushka.net.ua/';

    sendCallBackForm = async(formValues: FormikValues) => {

        try {
            const requestOptions: object = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                mode: 'cors'
            };
            const res = await axios.post(this._apiUrl, formValues, requestOptions);
             if ( !res.data.result )  {
                 console.log('res not ok', res);
                 return 0;
             }
            return 1;
        } catch (error) {
            console.log("Error", error);
        }
        return 0;
    }

}