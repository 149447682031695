import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import './styles.css';

let list: string[] = [
    'Комплексне обслуговування',
    'Підготовка та подача звітів',
    'Підготовка первинних документів',
    'Ведення операцій в системі "Клієнт-банк"',
    'Відновлення обліку',
    'Кадрове діловодство',
    'Розрахунок заробітної плати та подача відповідних звітів',
    'Додаткові послуги'
];

const Services:React.FC = () => {

    return <div className="service-main section">
                <Container>
                    <Row>
                        <Col className='text-center'>
                            <h2 className="section-header">Послуги</h2>
                            <div className="section-description text-center mb-3">
                                Для отримання більш детальної інформації про послуги, напишіть нам на електронну скриньку, зателефонуйте або закажіть зворотній дзвінок
                            </div>
                        </Col>
                    </Row>
                    <Row className="py-2">
                        {list.map( (item, index) => <Col key={index+1} md={6} xl={3} className="service-item">
                            <div className="service-item-key m-2">{index+1}</div>
                            <div className="service-item-value">{item}</div>
                        </Col>)}
                    </Row>
                </Container>
    </div>;
}

export default Services;