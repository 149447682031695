import React, {Fragment} from 'react';
import logo from '../../images/logo.webp';
import Photo from '../../images/Photo.webp';
import './styles.css';
import {Container, Row, Col, Button, Image} from "react-bootstrap";


const Header:React.FC = () => {

    const scrollToForm = () => {
        const form: any = document.querySelector('#callBackForm');
        form.scrollIntoView( { behavior: 'auto', block: 'start' } );
    }

    return <Fragment>
            <Container className="section header">
                <Row className="text-md-center">
                    <Col md={5} sm={12} className="text-md-start">
                        <div className="d-flex flex-md-row flex-column align-items-center align-items-md-start">
                            <Image src={logo} alt="logo" className="header__logo"/>
                            <div className="d-flex flex-column justify-content-center text-lg-start">
                                <b className='header__site-name'>SOVUSHKA</b>
                                <span>Ваша надійна бухгалтерія</span>
                            </div>
                        </div>
                    </Col>
                    <Col md={7} sm={12} className="header__contacts text-md-end">
                        <div className="d-flex flex-column flex-lg-row justify-content-end align-items-lg-center">
                            <div className="d-flex flex-column justify-content-center text-lg-end my-2 my-lg-0">
                                <a href="tel:+380677963644">
                                    +380 67 796 36 44
                                </a>
                                <a href="mailto:sovushka.mudraya.golovushka@gmail.com">
                                    sovushka.mudraya.golovushka@gmail.com
                                </a>
                            </div>
                            <div className="header__contacts-button">
                                <Button href="#form" className="site-btn" onClick={scrollToForm}>
                                    Замовити дзвінок
                                </Button>
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
            <div className="section container-fluid top-background">
                <Container>
                    <Row>
                        <Col className="col-12">
                            <Image alt="author-photo" src={Photo} className="header__author-photo img-fluid"/>
                        </Col>
                        <Col className="col-12 col-md-9  col-lg-7 col-xl-6 text-white align-items-start pb-4">
                            <h1 className="fw-bold text-start header__author-name">
                                Худенко Ольга Олександрівна<br/>
                                Бізнес-консультант · Бухгалтер
                            </h1>
                            <div className="text-start header__author-description">
                                Допомагаємо розібратися у податковому законодавстві,
                                побудувати правильний та якісний облік
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
    </Fragment>
}

export default Header;