import facebook from "../images/logo-facebook.svg";
import instagram from "../images/logo-instagram.svg";
import whatsup from "../images/logo-whatsup.svg";
import viber from "../images/logo-viber.svg";
import telegram from "../images/logo-telegram-app.svg";

type socialLinksType = {
    href: string,
    alt: string,
    name: string
}

const socialLinks: socialLinksType[] = [
    {
        href: "https://www.facebook.com/BuhgalterSovushka",
        alt: "facebook",
        name: facebook
    },
    {
        href: "https://www.instagram.com/buhgaltersovushka/",
        alt: "instagram",
        name: instagram
    },
    {
        href: "https://wa.me/380677963644",
        alt: "whatsup",
        name: whatsup
    },
    {
        href: "viber://chat/?number=%2B380%2067%20796%2036%2044",
        alt: "viber",
        name: viber
    },
    {
        href: "https://t.me/BuhgalterSovushka",
        alt: "telegram",
        name: telegram
    }
]

export {
    socialLinks
}

export type {
    socialLinksType
}