import React from 'react';
import sovushka from "../images/Sova.webp";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from 'react-router-dom';

const ErrorNotFoundPage: React.FC = () => {
    return(
        <Container fluid className="mt-5">
            <Row className="flex-column align-items-center">
                <Col xs={7} sm={5} lg={4} className="container-fluid">
                    <img src={sovushka} alt="Error" className="error__image img-fluid"/>
                </Col>
                <Col sm={12} className="text-center">
                    <h2 className="error__header fw-bold">404</h2>
                    <div><Link to={`/`}>Повернутися на головну</Link></div>
                </Col>
            </Row>
        </Container>
    )
};

export default ErrorNotFoundPage;