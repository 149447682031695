import React from 'react';
import Header from "./components/header";
import Services from "./components/services";
import Rates from "./components/rates";
import Content from "./components/content";
import FeedbackList from "./components/feedback";
import CallBackForm from "./components/callbackform";
import Contacts from "./components/contacts";
import {ScrollToTop} from "./components/common";

import './App.css';
import "swiper/css/bundle";

import ErrorBoundary from "./error/error-boundary";

//     xs: 0,
//     sm: 576px,
//     md: 768px,
//     lg: 992px,
//     xl: 1200px,
//     xxl: 1400px

function App() {
  return (
      <div className="App">
          <ErrorBoundary>
              <Header />
              <Services/>
              <Rates/>
              <Content/>
              <FeedbackList/>
              <CallBackForm/>
              <Contacts/>
              <ScrollToTop/>
          </ErrorBoundary>
      </div>
  );
}

export default App;
