import React, {Fragment, useState} from 'react';
import {Formik, Field, Form, ErrorMessage} from "formik";
import {Col, Container, Row} from "react-bootstrap";
import './styles.css';
import ErrorBoundary from "../../error/error-boundary";
import DataService from "../../services/DataService";

interface MyFormValues {
    fio: string,
    tel: string,
    comment: string
}



const CallBackForm: React.FC<{}> = () => {

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSendError, setIsSendError] = useState(false);
    const [isSendSuccess, setIsSendSuccess] = useState(false);
    const DS = new DataService();

    const validate = (values: MyFormValues) => {
        const errors: any = {};

        if (!values.fio) {
            errors.fio = "Обов'язкове поле для заповлення!";
        } else if (values.fio.length > 20) {
            errors.fio = "Ім'я не повинно перевищувати 20 символів";
        }

        if (!values.tel) {
            errors.tel = "Обов'язкове поле для заповлення!";
        } else if (values.tel.length !== 10) {
            errors.tel = "У національному форматі довжина номеру складає 10 цифр. Код країни можна зазначити у коментарії";
        }

        return errors;
    }

    const SendErrorMessage = <Fragment>
        <div className='text-danger py-2'>Щось пішло не так... Спробуйте, будь-ласка, пізніше</div>
    </Fragment>;
    const SendSuccessMessage = <Fragment>
        <div className='text-success py-2 text-center'>Дякуємо, що звернулися до нас. Ми обов'язково Вам зателефонуємо</div>
    </Fragment>;

    let updateFalseSend = () => {
        setIsSendError(true);
        setTimeout(() => setIsSendError(false), 5000);
        setTimeout(() => setIsSubmitted(false), 5000);
    }

    let updateSuccessSend = () => {
        setIsSendSuccess(true);
        setTimeout(() => setIsSendSuccess(false), 5000);
        setTimeout(() => setIsSubmitted(false), 5000);

    }

    return (
        <ErrorBoundary>
            <div className="callback-form__wrap section" id="callBackForm">
                <h2 className="section-header">Зворотній дзвінок</h2>
                <Container>
                    <Row>
                        <Col className="section-description text-center">
                            Введіть свій контактній номер телефону і ми зателефонуємо Вам на протязі 15 хвилин
                        </Col>
                    </Row>
                </Container>
                <Formik initialValues={{ fio: '', tel: '', comment: '' }}
                        validate={validate}
                        onSubmit={async (values, { resetForm }) => {
                            setIsSubmitted(true);
                            resetForm();
                            DS.sendCallBackForm(values)
                                .then((res: number) => {
                                    res ?
                                        updateSuccessSend():
                                        updateFalseSend()
                                });
                        }}
                >
                    <Container fluid>
                        <Row className='justify-content-center'>
                            <Col sm={9} md={6}>
                                <Form>
                                    <div className="mb-3">
                                        <label htmlFor="fio" className="form-label float-start">Введіть Ваше ім'я</label>
                                        <Field type="text" name="fio" className="form-control"/>
                                        <div className="error text-start">
                                            <ErrorMessage name="fio"/>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="tel" className="form-label float-start">Введіть номер свого телефону</label>
                                        <Field type="tel" name="tel" placeholder="Номер у міжнародному форматі без коду країни" className="form-control"/>
                                        <div className="error text-start">
                                            <ErrorMessage name="tel"/>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="comment" className="form-label float-start">Додайте коментарій</label>
                                        <Field as="textarea" name="comment" placeholder="Тут можно вказати код країни для номеру телефона" className="form-control"/>
                                        <ErrorMessage name="comment" className=""/>
                                    </div>

                                    <div className="text-center">
                                        <button  type="submit" className="site-btn btn btn-primary" disabled = {isSubmitted}>Надіслати</button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Formik>
                {isSendError && SendErrorMessage}
                {isSendSuccess && SendSuccessMessage}
            </div>
        </ErrorBoundary>

    );
}

export default CallBackForm;