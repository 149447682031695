import React, {Fragment} from 'react';
import './styles.css';
import { Container, Row, Col } from "react-bootstrap";
import Star from '../../images/icons/star.png';
import {FeedbacksArray, IFeedback} from "../../data/FeedbackData";
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/virtual';



const SimpleSlider:React.FC = () => {

    let showRating = (star: number) => {
        let view = [];
        let i = star;
        while (i > 0) {
            view.push(<img src={Star} className='star-rating' alt=''/>);
            i--;
        }
        return <div>{view}</div>;
    }

    return (
        <div className='feedback section'>
            <h2 className="section-header">Про нас кажуть</h2>
            <Container>
                <Row>
                    <Swiper autoplay={{delay: 4000, disableOnInteraction: false }}
                            pagination={true}
                            modules={[Pagination, Autoplay]}
                            autoHeight={true}
                            className="mySwiper">
                        {FeedbacksArray.map((slideContent: IFeedback, index:number) => (
                            <Fragment  key={slideContent.key}>
                                <SwiperSlide virtualIndex={index} className="swiper-slide">
                                    <Col className="md-5 align-items-center">
                                        <div>
                                            <img src={slideContent.authorImg} alt={slideContent.authorName} className="feedback__image"/>
                                        </div>
                                        <div className="feedback__text">
                                            {slideContent.text}
                                        </div>
                                        <div className="feedback__star">
                                            { showRating(slideContent.rating) }
                                        </div>
                                        <div className="feedback__author-name">
                                            {slideContent.authorName}
                                        </div>
                                    </Col>
                                </SwiperSlide>
                            </Fragment>
                        ))}
                    </Swiper>
                </Row>
            </Container>
        </div>
    );
};

export default SimpleSlider;