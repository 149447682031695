import React, {Fragment} from 'react';
import './styles.css';
import {Col, Container, Row} from "react-bootstrap";
import {socialLinks, socialLinksType} from "../../data/SocialLinksData";


const Contacts:React.FC = () => {
    return <Fragment>
        <Container className="section contacts">
            <Row>
                <h2 className="section-header">Контакти</h2>
                <div className="text-center">
                    <div className="contacts__phone">
                        <a href="tel:+380677963644">
                            +380 67 796 36 44
                        </a>
                    </div>
                    <div className="contacts__email">
                        <a href="mailto:sovushka.mudraya.golovushka@gmail.com">
                            sovushka.mudraya.golovushka@gmail.com
                        </a>
                    </div>
                    <div className="contacts__address">Украйна, м. Київ, вул. В.Котарбинського, 19</div>
                </div>
            </Row>
        </Container>
        <Container fluid>
            <Row>
                <Col className="contacts__social">
                    {socialLinks.map((socialLink: socialLinksType, index:number) => (
                        <a href={socialLink.href} key={index} target="_blank" rel="noreferrer">
                            <img src={socialLink.name} alt={socialLink.alt}/>
                        </a>
                    ))}
                </Col>
            </Row>
        </Container>
    </Fragment>;
}

export default Contacts;