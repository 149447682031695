import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import Parser from 'html-react-parser';
import {Separator} from "../common";
import {rates} from "../../data/RatesData";


//
//Small	sm	≥ 576px
//Medium	md	≥768px
//Large	lg	≥ 992px


const Rates:React.FC = () => {
    return <Container className="section">
        <Row>
            <Col sm={12}>
                <h2 className="section-header">Тарифи</h2>
                <Separator/>
            </Col>
            {
                rates.map((item, key) => {
                    return <React.Fragment key={key}>
                        <Col sm={12} className="my-2">
                            <Row className="text-start">
                                <Col sm={12} md={6} className="mb-2">
                                    <div className="fw-bold">{item.title}</div>
                                    <div className="fst-italic">{item.description}</div>
                                </Col>
                                <Col sm={12} md={6}>
                                    {Parser(item.rates)}
                                </Col>
                            </Row>
                        </Col>
                        <Separator/>
                    </React.Fragment>
                })
            }
        </Row>

    </Container>;
}

export default Rates;