import {Component} from 'react';
import Error from "./error";

type ErrorProps = {
    children: any
}

export default class ErrorBoundary extends Component<ErrorProps>{

    state = {
        error: false
    };

    componentDidCatch(error: Error, errorInfo: any) {
        this.setState({
            error: true
        })
    }

    render() {
        if(this.state.error) return <Error/>;
        return this.props.children;
    }

}