import React from 'react';
import supernova from "./supernova.webp";
import {Container, Row, Col} from "react-bootstrap";

const Error: React.FC = () => {
    return(
        <Container fluid className="mt-5">
            <Row className="flex-column align-items-center">
                <Col xs={7} sm={5} lg={4} className="container-fluid">
                    <img src={supernova} alt="Error" className="error__image img-fluid"/>
                </Col>
                <Col sm={12} md={5} className="text-center">
                    <div className="error__header fw-bold">Ups! Щось пішло не так!</div>
                    <div className="error__text">Виникла проблема, але ми вже працюємо над нею</div>
                </Col>
            </Row>
        </Container>
    )
};

export default Error;