import EvgeniyLebed from "../images/feedbacks/Evgeniy_Lebed.png";
import MarinaBondarenko from "../images/feedbacks/_.png";
import OleksiiMarkovetsSo from "../images/feedbacks/Oleksii_Markovets-So.png";
import Gngamer from "../images/feedbacks/GNGAMER.png";
import DmytroNudga from "../images/feedbacks/unnamed.png";
import Nadiia_p from "../images/feedbacks/Nadiia_p.png";
import Daria from "../images/feedbacks/daria.png";
import Transatellit from "../images/feedbacks/transatellit.png";
import Tsarenko from "../images/feedbacks/tsarenko.png";
import Bilainchik from "../images/feedbacks/bilainchik.png";
interface IFeedback {
    text: string,
    authorName: string,
    authorImg: any,
    rating: number,
    key: number
}

const FeedbacksArray:IFeedback[] = [
    {
        text: 'Дуже задоволена роботою Ольги Худенко. \n'+
            'Я новачок у звітах та особливостях роботи з бухгалтерський документами, вона справжній професіонал. \n'+
            'Все доступно пояснювала та виконала свою роботу швидко і якісно. Рекомендую',
        authorName: 'Татьяна Билайнчик',
        authorImg: Bilainchik,
        rating: 5,
        key: 1
    },
    {
        text: 'Співпрацюємо вже багато років. Висококваліфікований спеціаліст. Вирішує проблемні питання. \n' +
            ' Завжди у темі останніх податкових та законодавчих змін. І просто чудова людина. Рекомендую \n' +
            'до співпраці.',
        authorName: 'Евгений Лебедь',
        authorImg: EvgeniyLebed,
        rating: 5,
        key: 2
    },
    {
        text: 'У нас были вопросы по валютной выручке, аренде помещений и нужны были консультации по налогам. Команда специалистов помогла навести порядок и убрать ошибки прошлого бухгалтера. Главный специалист Ольга Худенко очень хорошо проконсультировала и помогла разобраться с налогами. Теперь нашу бухгалтерию никому не доверим, только с ними работать дальше! Спасибо!',
        authorName: 'Марина Бондаренко',
        authorImg: MarinaBondarenko,
        rating: 5,
        key: 3
    },
    {
        text: 'Ми співпрацюємо з ведення мого ФОП ось уже кілька років і маю лише позитивні враження.\n' +
            'Усі операції зі звітами та податками проводяться у строк та якісно, так що у мене \n' +
            'не виникало жодних проблем з контролюючими органами, на відміну від моїх друзів, які ведуть \n' +
            'свою бухгалтерію самі :)',
        authorName: 'Oleksii Markovets-Sobolevkyi',
        authorImg: OleksiiMarkovetsSo,
        rating: 5,
        key: 4
    },
    {
        text: 'відкрив фоп, 3 група з єдиним податком. чудово проконсультувала Ольга, докладно розповіла, надала приклади документів.\n' +
            'ціна прийнятна, зараз це важливо. Дякуємо!',
        authorName: 'GTGAMER 3.0',
        authorImg: Gngamer,
        rating: 5,
        key: 5
    },
    {
        text: 'Той випадок, коли можна повністю довіриться людині та її досвіду.\n' +
            'Співпрацюємо протягом року, дуже сподіваюся, що співпраця "на віки".\n' +
            'Клієнтоорієнтованість, готовність допомогти в будь-якому питанні, доброзичливість, ах.... ця посмішка))\n' +
            'Обожнюю вас.',
        authorName: 'Дмитрий Нудьга',
        authorImg: DmytroNudga,
        rating: 5,
        key: 6
    },
    {
        text: 'Дуже відповідальний фахівець і хороша людина. Робота виконується швидко. І що важливо, \n '+
            'Ольга завжди готова дати вичерпну консультацію, якщо у вас виникають питання.',
        authorName: 'Nadiia P',
        authorImg: Nadiia_p,
        rating: 5,
        key: 7
    },
    {
        text: 'Ольга - досвічений професіонал свого діла та дуже уважна людина, яка завжди готова \n'+
            'допомогти, 100% довіра та впевненість. Дякую за співпрацю!',
        authorName: 'Daria Volkova',
        authorImg: Daria,
        rating: 5,
        key: 8
    },
    {
        text: 'Ведення бухгалтерського обліку нашої компанії вже 3 роки в надійних руках Ольги. Дякую, \n'+
            'що Ви є і, сподіваюся, наша співпраця продовжиться на довгі роки! Винятковий професіоналізм, \n'
            +'клієнтоорієнтованість та підтримка 24/7 у питаннях бухгалтерського та податкового обліку! \n'+
            'Успіхів Вам та процвітання!',
        authorName: 'transatellit transatellit',
        authorImg: Transatellit,
        rating: 5,
        key: 9
    },
    {
        text: '',
        authorName: 'Евгения Царенко',
        authorImg: Tsarenko,
        rating: 5,
        key: 10
    }
]

export {
    FeedbacksArray
};
export type { IFeedback };
