interface IRates {
    title: string,
    description: string,
    rates: string
}

const rates:IRates[] = [
    {
        title: 'Комплексне бухгалтерське обслуговування',
        description: 'Всі тарифи включають в себе: прийом та обробку первинної бухгалтерської документації реєстрацію бухгалтерських операцій в обліковій системі (на основі первинних документів) нарахування податків нарахування та подача бухагалтерської та податкової звітності в податкові органи та органи статистики складання платіжних доручень по сплаті податків',
        rates: "Неприбуткові організації - ОСМД (ОСББ) від 4500 грн/міс <br/>" +
            "ФОП Єдиний податок 1-2 група від 800 грн <br/> " +
            "ФОП Єдиний податок 3 група без ПДВ від 1500 грн/міс <br/> " +
            "ФОП Єдиний податок 3 група з ПДВ від 3500 грн/міс <br/> " +
            "ФОП Загальна система без ПДВ від 3000 грн/міс <br/> " +
            "ФОП Загальна система з ПДВ від 4500 грн/міс <br/> " +
            "ЮО Єдиний податок без ПДВ від 3000 грн/міс <br/> " +
            "ЮО Єдиний податок з ПДВ від 3500 грн/міс <br/> " +
            "ЮО Загальна система без ПДВ від 3000 грн/міс <br/>" +
            "ЮО Загальна система з ПДВ від 5500 грн/міс <br/>"
    },
    {
        title: 'В тарифи не входять та розраховуються додатково',
        description: '',
        rates: "Виписка первинних документів (рахунки, видаткові накладні, акти наданих послуг та ін.)" +
            "<ul>" +
            "<li>до 5 позицій - 25 грн/док</li>" +
            "<li>до 10 - 35 грн/док</li>" +
            "<li>до 15 - 45 грн/док</li>" +
            "</ul>" +
            "Відновлення обліку (бухгалтерського, кадрового, подання уточнюючих розрахунків) - договірна<br/>" +
            "Разова подача звітності:" +
            "<ul>" +
            "<li>місячні від 1500 грн</li>" +
            "<li>квартальні від 2000 грн</li>" +
            "<li>річні від 3000 грн</li>" +
            "</ul>" +
            "Нульові звіти без діяльності 1500 грн/звіт<br/>" +
            "Ведення операцій в системі \"клієнт-банк\" по р/р клієнта:" +
            "<ul>" +
            "<li>до 20 док. 750 грн/міс</li>" +
            "<li>до 40 док. 950 грн/міс</li>" +
            "<li>до 60 док. 1300 грн/міс</li>" +
            "</ul>" +
            "більша кількість документів - договірна"
    },
    {
        title: 'Кадрове діловодство',
        description: '',
        rates: "Постановка кадрового обліку \"з нуля\"(накази, штатний розклад, графіки відпусток, трудові договори, особові картки та ін.):" +
            "<ul>" +
            "<li>до 5 співробітників 2800 грн/міс</li>" +
            "<li>до 15 співробітників 8300 грн/міс</li>" +
            "<li>до 25 співробітників 13800 грн/міс</li>" +
            "<li>від 26 співробітників 450 грн/люд</li>" +
            "</ul>" +
            "Відновлення кадрового діловодства - договірна"
    },
    {
        title: 'Розрахунок заробітної плати та подача об\'єднаного звіту ЄСВ та 1ДФ',
        description: '',
        rates: "Розрахунок з/п до 1 співробітників входить в базовий пакет." +
            "<ul>" +
            "<li>до 10 співробітників - 600 грн/люд</li>" +
            "<li>до 15 співробітників - 550 грн/люд</li>" +
            "<li>до 20 співробітників - 500 грн/люд</li>" +
            "</ul>" +
            "Розрахунок заробітної плати без базового пакету 650 грн/співробітник<br/>" +
            "Підготовка об'єднаної звітності з ЄСВ та 1ДФ подача звіту для організацій на обслуговуванні входить в базовий пакет подача звіту без розрахунку заробітної плати - 2500 грн/звіт (до 10 співробітників)"
    },
    {
        title: 'Додаткові послуги',
        description: '',
        rates: "Разові консультації, які не потребують заглиблення в питання\n" +
            "<ul>" +
            "<li>усні від 500 грн.</li>" +
            "<li>письмові від 1500 грн.</li>" +
            "</ul>" +
            "Складання листів для податкової -- договірна<br/>" +
            "Заповнення форм, заяв… - договірна"
    }
];

export {
    rates
}