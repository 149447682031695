import React, {Fragment, useState} from 'react';
import ScrBtn from '../../images/icons/arrow-up-circle-fill.svg';

const ScrollToTop:React.FC = () => {

    const [visible, setVisible] = useState<Boolean>(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            setVisible(true)
        }
        else if (scrolled <= 300){
            setVisible(false)
        }
    };

    window.addEventListener('scroll', toggleVisible);

    return <Fragment>
        <button onClick={scrollToTop} className={`scrollToTopBtn ${visible ? 'visible': ''}`}>
            <img src={ScrBtn} alt='TOP'/>
        </button>
    </Fragment>
}

export default ScrollToTop;