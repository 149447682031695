import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import './styles.css';
import credit_card from '../../images/icons/credit-card-fill.svg';
import credit_card_2 from '../../images/icons/credit-card-2-back.svg';
import money from '../../images/icons/money.svg';
import money_ from '../../images/icons/money_.svg';
import cash from '../../images/icons/cash.svg';

interface ISpends {
    imageUrl: any,
    title: string
}

const spends:ISpends[] = [
    {imageUrl: credit_card, title: 'Заробітна плата'},
    {imageUrl: credit_card_2, title: 'Податки і внески'},
    {imageUrl: money, title: 'Плата за відпускні та лікарняні'},
    {imageUrl: money_, title: 'Витрати на робоче місце бухгалтера (IT, офісне програмне забезпечення телефони, канцтовари, техніка і т. д.)'},
    {imageUrl: cash, title: 'Витрати на бухгалтерські програми для обліку та звітності'},
    {imageUrl: credit_card, title: 'Витрати на підвищення кваліфікації бухгалтера - підписки на бухгалтерські журнали, семінари щодо змін в законодавстві, джерела правової інформації'}
];



const Content:React.FC = () => {

    return <Container>
         <h2 className="section-header">Чому бухгалтерія на аутсорсингу дешевше, ніж штатна?</h2>
        <Row>
            <Col className="">
                <p>Давайте по розмірковуємо. Як показує практика, аутсорсинг бухгалтерських процесів, розрахунку заробітної плати і кадрового адміністрування дозволяє скоротити витрати на ці функції до 30%.</p>
                <p>Аутсорсинг позбавляє компанію від необхідності наймати на повну ставку співробітників, які виконують невеликі за обсягом завдання.</p>
                <p>Спеціалізовані бухгалтерські компанії, як правило, широко застосовують автоматизацію робочих процесів, що дозволяє скоротити використання людської праці і знизити витрати.</p>
                <p>При порівнянні вартості аутсорсингу з вартістю штатних співробітників потрібно повністю враховувати всі витрати на бухгалтерію, а не тільки оплату праці співробітників.</p>
                <p>У ці витрати входить:</p>
                <Row className="text-center">
                    {
                      spends.map( (item, key) => {
                         return <Col md={6} lg={4} key={key} className="py-3">
                             <Row>
                                 <Col sm={12}>
                                     <img src={item.imageUrl} alt={item.title} className="content-fa-icon"/>
                                 </Col>
                                 <Col sm={12}>
                                    <span>{item.title}</span>
                                 </Col>
                             </Row>
                          </Col>
                      })
                    }
                </Row>
            </Col>
        </Row>
        <Row className="justify-content-md-center">
            <Col className="mt-4" md={12} lg={8}>
                <div className='content-float-block'>
                    Самий більший жах який лякає власників (керівників) підприємств - якщо викличуть до Податкової
                </div>
                <p>Все це - необхідні витрати, так як бухгалтерське і податкове законодавство постійно змінюється.</p>
                <p>Виходить, що до заробітної плати співробітників, які ведуть облік і супроводжуючих бухгалтерські
                    процеси, потрібно додати 50-70% від заробітної плати. І вже цю суму потрібно порівнювати з тією
                    цифрою, яку пропонує аутсорсингова фірма.</p>
                <p>Коли компанія передає облікові функції на аутсорсинг, всі витрати перекладаються на постачальника
                    послуг. Підсумкова вартість обмежиться сумою, яка буде прописана в договорі.</p>
                <p>Ми працюємо так, що в Податкову наших клієнтів не викликають. Якщо є у Податкової питання з приводу
                    діяльності клієнта, то все вирішується по електронних каналах зв'язку або телефону. За необхідності
                    ми виїжджаємо до клієнта та супроводжуємо будь-які перевірки.</p>
            </Col>
        </Row>
    </Container>;
}

export default Content;